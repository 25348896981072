/* eslint-disable */
import React from 'react'
import { graphql } from 'gatsby'

import womanInGarden from '../images/heroes/woman-in-garden.jpg'
import medicareAdvantageFormula from '../images/icons/medicare-advantage-formula.svg'
import wheelchairHug from '../images/people/wheelchair-hug.jpg'
import pharmacyVisit from '../images/people/pharmacy.jpg'
import coffeePhone from '../images/people/coffee-phone.jpg'

import { StaticImage } from 'gatsby-plugin-image'
import CountdownTimer from '../components/Countdown'
import DynamicCTACard from '../components/DynamicCTACard'
import SeoCtaBar from '../components/SeoCtaBar'
import CobraBanner from '../components/CobraBanner'

import {
  Accordion,
  Columns,
  Column,
  Hero,
  Link,
  LinkButton,
  List,
  ListItem,
  SplitContent,
  Stack,
  Typography,
  VariableContent,
  useMapi,
} from '@leshen/gatsby-theme-leshen'
import { Layout } from '@leshen/gatsby-theme-contentful'

import footer from '../components/Footer'
import header from '../components/Header'

const Home = ({ data }) => {
  const { rotatedNumber } = useMapi()
  const footerDisclaimers = ['disclaimer-aet-medicareadvantage']

  const layoutProps = {
    data: {
      contentfulPage: {
        header: header(),
        footer: footer(footerDisclaimers, data.allBrandyDisclaimer.edges),
        seo: {
          title:
            'Aetna Medicare Advantage Plans | 855-200-5707 | Medicare Part C',
          description:
            'Medicare Advantage plans from Aetna offer additional benefits, such as vision, dental, and hearing. To sign up for Aetna Medicare Part C',
        },
        path: '/advantage',
        promoCode: '120769',
        ringpool: 'MED',
        hideStickyCTA: false,
        spanish: false,
        sections: [
          {
            // Add any relevant section data
          },
        ],
      },
      site: {
        siteMetadata: {
          siteURL: 'https://www.aetnamedicaredirect.com/',
          siteName: 'aetnamedicaredirect',
          alternateName: 'aetnamedicaredirect',
          removeTrailingSlashes: true,
        },
      },
    },
    titleTemplate: 'aetnamedicare',
    defaultRobots: 'follow,index',

    main: (
      <>
        <div className="hero-content">
          <div className="wrapper">
            <Hero
              variant="full"
              className="leshen-hero"
              image={
                <img
                  src={womanInGarden}
                  alt="elderly woman tending flowers in a garden"
                />
              }
              mainContent={
                <>
                  <Typography variant="h1">
                    Aetna<sup>®</sup> Medicare Advantage{' '}
                  </Typography>

                  <DynamicCTACard />
                </>
              }
            />
          </div>
        </div>
        <SeoCtaBar />
        <CountdownTimer />
        <SplitContent
          alignImageToBottom={false}
          image={
            <img
              src={wheelchairHug}
              alt="an elderly man in a wheelchair gives grandchild a hug"
            />
          }
          mainContent={
            <>
              <Typography variant="h2">What is Medicare Advantage?</Typography>
              <Typography variant="body">
                Medicare Part C, or Medicare Advantage, is a federally regulated
                health insurance program designed to help people aged 65 and
                over (and some younger people with disabilities) gain access to
                medical services, supplies, and equipment.
              </Typography>
              <Typography variant="body">
                Medicare Advantage plans are unique because they are provided by
                private insurance companies. By law, all Medicare Advantage
                plans must provide at least the same coverage as Medicare Part A
                and Part B (also known as Original Medicare), though many offer
                additional coverage and benefits.
              </Typography>
            </>
          }
        />
        <CobraBanner />
        <VariableContent
          backgroundColor="lightGray"
          alignMainContent="left"
          mainContent={
            <>
              <Typography variant="h2">
                Aetna Medicare Part C gives your health an advantage
              </Typography>
              <img
                src={medicareAdvantageFormula}
                alt="Medicare Part A + Medicare Part B + More = Medicare Advantage formula illustration"
                className="margin-bottom-md"
              />
              <Typography variant="body">
                Why pay for a plan without benefits you need? Aetna’s Medicare
                Advantage plans cover all the same health services as Original
                Medicare, but with additional benefits. Talk to a licensed agent
                today to find a plan near you that fits your needs and your
                budget.
              </Typography>
              <Typography variant="body">
                In addition to Original Medicare benefits, Aetna Medicare
                Advantage plans may include coverage for:
              </Typography>
              <List>
                <ListItem>Vision care</ListItem>
                <ListItem>Dental care</ListItem>
                <ListItem>Health and wellness programs</ListItem>
                <ListItem>Gym memberships</ListItem>
              </List>
            </>
          }
        ></VariableContent>

        <VariableContent
          mainContent={
            <>
              <Typography variant="h2">
                What kind of network does Aetna Medicare Advantage offer?
              </Typography>
              <Typography variant="body">
                Aetna offers both Health Maintenance Organization (HMO) and
                Preferred Provider Organization (PPO) plans. Both types of plans
                provide access to medical practitioners and services within a
                specified network of providers, but with some key differences.
                And their Dual eligible special needs plans (DSNP) add an extra
                layer of coverage for those who qualify. DSNP is a special type
                of a Medicare Advantage Prescription Drug plan.
              </Typography>
            </>
          }
          backgroundColor=""
          alignMainContent="center"
        >
          <Stack>
            <Columns>
              <Column className="card center-content box-shadow">
                <div className="top-content">
                  <StaticImage
                    src="../images/icons/purple-hmo.svg"
                    alt="HMO icon"
                    layout="fixed"
                    className="product-icons"
                  />
                  <Typography variant="h5" color="primary">
                    Aetna Medicare HMO plans
                  </Typography>
                  <List>
                    <ListItem>
                      Offer lower premiums than most PPO plans, with a lower
                      deductible or no deductible at all
                    </ListItem>
                    <ListItem>
                      Only cover doctors and services that are in your network
                      (out-of-network care will be your responsibility)
                    </ListItem>
                    <ListItem>
                      May require a referral from your primary care physician
                      before you see a specialist
                    </ListItem>
                  </List>
                </div>
              </Column>
              <Column className="card center-content box-shadow">
                <div className="top-content">
                  <StaticImage
                    src="../images/icons/purple-ppo.svg"
                    alt="PPO icon"
                    layout="fixed"
                    className="product-icons"
                  />
                  <Typography variant="h5" color="primary">
                    Aetna Medicare PPO plans
                  </Typography>
                  <List>
                    <ListItem>
                      Cover both in- and out-of-network services (though
                      out-of-network services may cost more)
                    </ListItem>
                    <ListItem>
                      Allow you to see any doctor or specialist that accept
                      Medicare with no referral necessary
                    </ListItem>
                    <ListItem>
                      Generally have higher premiums and deductibles than HMO
                      plans
                    </ListItem>
                  </List>
                </div>
              </Column>
              <Column className="card center-content box-shadow">
                <div className="top-content">
                  <StaticImage
                    src="../images/icons/purple-dsnp.svg"
                    alt="DSNP icon"
                    layout="fixed"
                    className="product-icons"
                  />
                  <Typography variant="h5" color="primary">
                    Aetna Medicare DSNP plans
                  </Typography>
                  <List>
                    <ListItem>
                      Available in 14 states across the country to those who
                      receive both Medicare and Medicaid benefits
                    </ListItem>
                    <ListItem>
                      Additional dental, vision, and hearing coverage
                    </ListItem>
                    <ListItem>
                      Over-the-counter products and prescription drug coverage
                    </ListItem>
                  </List>
                </div>
              </Column>
            </Columns>
          </Stack>
        </VariableContent>

        <SplitContent
          alignImageToBottom={false}
          image={
            <img
              src={pharmacyVisit}
              alt="an elderly man visits pharmacy and picks up medications"
            />
          }
          mainContent={
            <>
              <Typography variant="h2">
                Get prescription drug coverage with Medicare Parts C and D
              </Typography>
              <Typography variant="body">
                In some situations, Original Medicare coverage is not enough.
                Enroll in Medicare Parts C or D to receive prescription drug
                coverage above and beyond what Original Medicare offers.
              </Typography>
              <Typography variant="body">
                Aetna’s Medicare Advantage Prescription Drug plans (MAPD) offer
                all the benefits of Original Medicare plus covered prescription
                drugs.
              </Typography>
              <Typography variant="body">
                Speak with a licensed agent to learn more about your plan
                options.
              </Typography>
            </>
          }
        />
        <CobraBanner />
        <SplitContent
          backgroundColor="lightGray"
          alignImageToBottom={false}
          image={
            <img
              src={coffeePhone}
              alt="an elderly woman holds coffee in hand while talking on mobile phone"
            />
          }
          mainContent={
            <>
              <Typography variant="h2">
                Enroll in a Medicare Advantage plan today!
              </Typography>
              <Typography variant="body">
                Ready to enroll in a plan? Get started by speaking with a
                licensed agent today at{' '}
                <Link to={`tel:${rotatedNumber}`}>{rotatedNumber}</Link>.
              </Typography>
              <div className="card bg-light">
                <Typography variant="h4">
                  Request your complimentary Medicare insurance quote{' '}
                </Typography>
                <LinkButton
                  variant="feature"
                  to="/form"
                  color="primary"
                  className="margin-x-auto"
                >
                  Get Quote
                </LinkButton>
              </div>
            </>
          }
        />

        <VariableContent>
          <Columns className="icon-two-column">
            <Column className="icon-column">
              <StaticImage
                src="../images/icons/purple-magnify.svg"
                alt="magnifying glass icon"
                layout="fixed"
                width={100}
                height={100}
                className="column-icon"
              />
            </Column>
            <Column className="content-column">
              <Typography variant="h2">Frequently asked questions</Typography>
              <Stack spacing="xxl" alignMainContent="center">
                <Accordion
                  items={[
                    {
                      title: 'Why do I need Medicare Part C?',
                      content: (
                        <>
                          <Typography>
                            Although Medicare Part C (Advantage) is optional, it
                            may add benefits you wouldn’t normally receive from
                            Original Medicare. Because the private sector
                            provides Medicare Advantage, the minimum
                            requirements for these plans assure you receive all
                            the benefits that come with Original Medicare. In
                            some cases, Part C plans come with additional
                            coverage for dental, vision, and hearing. Some even
                            include prescription drug coverage as well.
                          </Typography>
                        </>
                      ),
                    },
                    {
                      title:
                        'What is the difference between Medicare Advantage and Medicare Supplement?',
                      content: (
                        <>
                          <Typography>
                            Medicare Advantage plans are private plans that are
                            an alternative to Original Medicare. These plans
                            often come with added benefits. Medicare Advantage
                            plans usually have lower premiums than a Medicare
                            Supplement plan.
                          </Typography>
                          <Typography>
                            A Medicare Supplement plan (Medigap) is supplement
                            insurance that you can add to your Original Medicare
                            plan. Medigap covers the medical expenses that your
                            Original Medicare cannot cover. Although they may
                            come with higher premiums, Medicare Supplement plans
                            cover a wide array of hospitals and medical
                            providers across the U.S.
                          </Typography>
                        </>
                      ),
                    },
                    {
                      title: 'Am I eligible for a Medicare Advantage plan?',
                      content: (
                        <>
                          <Typography>
                            To qualify for a Medicare Advantage plan, you must
                            first be eligible for <i>both</i> Part A and Part B
                            of Medicare (sometimes called “Original Medicare”).
                            Typically this means you need to be at least 65
                            years old, though there are some exceptions.
                          </Typography>
                          <Typography>
                            If you have any questions or concerns regarding your
                            eligibility for Medicare or Aetna Medicare
                            Advantage, give us a call. One of our friendly,
                            licensed agents can help.
                          </Typography>
                        </>
                      ),
                    },
                    {
                      title: 'When can I enroll in Medicare Advantage?',
                      content: (
                        <>
                          <Typography>
                            If you’re enrolling in Medicare for the first time
                            and you qualify for <i>both</i> Part A and Part B of
                            Medicare, you can enroll in an Aetna Medicare
                            Advantage plan during your Initial Enrollment
                            Period. Your Initial Enrollment Period begins three
                            months before the month of your 65th birthday and
                            ends three months after your birth month.
                          </Typography>
                          <Typography>
                            If you’re already on Medicare and want to enroll in
                            a Medicare Advantage plan, you can do so during the
                            Annual Enrollment Period, which starts October 15
                            and ends December 7 every year.
                          </Typography>
                          <Typography>
                            Under{' '}
                            <a
                              href="https://www.medicare.gov/sign-up-change-plans/when-can-i-join-a-health-or-drug-plan/special-circumstances-special-enrollment-periods"
                              target="_blank"
                            >
                              some circumstances
                            </a>
                            , you may be able to enroll in a Medicare Advantage
                            plan during a Special Enrollment Period. For
                            example, if you’ve recently moved or if you’re
                            currently living in a nursing home. Talk to one of
                            our licensed agents today to see if your life
                            circumstances apply.
                          </Typography>
                        </>
                      ),
                    },
                    {
                      title:
                        'What is the Medicare Advantage out-of-pocket maximum?',
                      content: (
                        <>
                          <Typography>
                            Medicare Advantage plans include an out-of-pocket
                            maximum, which can help with your health care costs,
                            especially if you need an expensive medical
                            procedure. Your out-of-pocket maximum sets a limit
                            on the amount of money you must pay out of pocket
                            each year before your Medicare Advantage plan pays
                            for 100 percent of your covered health care
                            services.
                          </Typography>
                          <Typography>
                            The amount of your annual out-of-pocket maximum
                            varies from one plan to the nextand may change from
                            year to year. Keep in mind that your monthly premium
                            doesn’t contribute to your out-of-pocket maximum.
                          </Typography>
                          <Typography>
                            Talk to a Licensed agent today to get your free
                            Medicare quote, including details about your
                            out-of-pocket maximum, as well as premiums,
                            deductibles, and other out-of-pocket costs.
                          </Typography>
                        </>
                      ),
                    },
                    {
                      title: 'Why was Medicare Part C created?',
                      content: (
                        <>
                          <Typography>
                            Although Medicare Part C was actually created during
                            the Balance Budgeting Act of 1997, there were still
                            some benefits missing that the government felt
                            essential to Medicare.
                          </Typography>
                        </>
                      ),
                    },
                  ]}
                />
              </Stack>
            </Column>
          </Columns>
        </VariableContent>
        <CobraBanner />
      </>
    ),
  }

  return <Layout {...layoutProps} />
}

export default Home

export const query = graphql`
  query HomeQuery {
    allBrandyDisclaimer {
      edges {
        node {
          brandy_id
          text
          symbol
        }
      }
    }
  }
`
